import { Injectable, inject } from '@angular/core';
import { Constants } from '@app/constants';
import { AlertController, LoadingController, ToastController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  loader: HTMLIonLoadingElement;

  private alert = inject(AlertController);
  private loadingCtrl = inject(LoadingController);
  private toast = inject(ToastController);
  private translateService = inject(TranslateService);

  async alertMessage(title: string, msg: string, buttons?: string[]): Promise<void> {
    const alert = await this.alert.create({
      header: this.translateService.instant(title),
      message: this.translateService.instant(msg),
      buttons: buttons || [this.translateService.instant('button.ok')],
    });

    return await alert.present();
  }

  hideLoading(): void {
    // timeout needed for data to become available
    timer(Constants.dismissDelayLoader).subscribe(async () => {
      if (this.loader) {
        const result = await this.loader.dismiss();
        if (result) {
          this.loader = undefined;
        }
      }
    });
  }

  async openToastError(message: string, header?: string): Promise<void> {
    const toastLocation = 'bottom';
    const toast = await this.toast.create({
      header,
      message,
      icon: 'alert-circle-outline',
      color: 'danger',
      position: toastLocation,
      duration: Constants.dismissDelayToast,
      cssClass: `toast-${toastLocation}`,
    });

    await toast.present();
  }

  async openToastMessage(message: string, header?: string): Promise<void> {
    const toast = await this.toast.create({
      header,
      message: this.translateService.instant(message),
      icon: 'alert-circle-outline',
      color: 'secondary',
      duration: Constants.dismissDelayToast,
    });

    return toast.present();
  }

  /**
     * Show a loading modal with an animated AD6 biker and a cutomizable message. Will be shown until hideLoading() is called.
     *
     * @param message message that should be displayed. Can also be a key from a translation file if messageIsTranslateKey is set to true.
     * @param messageIsTranslateKey if set to true, the message is used as a key to find a translation
     * */
  async showLoading(message: string, messageIsTranslateKey?: boolean): Promise<void> {
    if (messageIsTranslateKey) {
      const translatedMessage = this.translateService.instant(message);
      if (translatedMessage === message) {
        message = this.translateService.instant('label.loading.generic');
      } else {
        message = translatedMessage;
      }
    }
    if (this.loader) {
      console.log('Er is al een loader actief');

      return;
    }

    this.loader = await this.loadingCtrl.create({
      spinner: null,
      message: `<ion-img src="assets/images/AD6_logo_anim.gif" alt="loading..."></ion-img><ion-label>${message}</ion-label>`,
      cssClass: 'ion-text-center ion-text-wrap ad6-loader',
      duration: 3000, // Maximum show time when not hidden (happens in rare cases)
    });

    return this.loader.present();
  }

}
